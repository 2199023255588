export enum EAggregators {
  CREDIT_KARMA = "CreditKarma",
  CREDIT_KARMA_STORE_CARD = "CreditKarmaStoreCard",
  CLEARSCORE = "Clearscore",
  CLEARSCORE_STORE_CARD = "ClearscoreStoreCard",
  TOTALLY_MONEY = "TotallyMoney",
  TOTALLY_MONEY_STORE_CARD = "TotallyMoneyStoreCard",
  MONEY_SUPERMARKET = "MoneySupermarket",
  VANQUIS = "Vanquis",
  MONEVO = "Monevo",
  TUK = "Tuk",
}

export const buildBitsUniversalLink = (aggregator: string, aggregatorUid: string) => {
  if (!process.env.REACT_APP_BITS_BASE_UNIVERSAL_LINK) {
    return "";
  }
  return `${process.env.REACT_APP_BITS_BASE_UNIVERSAL_LINK}?aggregator=${aggregator}&aggregatorUid=${aggregatorUid}`;
};
